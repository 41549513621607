<template>
  <div style="height: 100%">
    <el-table
      :data="tableData"
      height="100%"
      :border="true"
      :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    >
      <el-table-column
        type="index"
        width="80"
        align="center"
        label="序号"
      ></el-table-column>
      <el-table-column
        prop="id"
        align="center"
        min-width="100"
        label="优惠券ID"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="name"
        align="center"
        min-width="120"
        label="优惠券名称"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="value" align="center" label="面额">
      </el-table-column>
      <el-table-column prop="out_num" align="center" label="已发放/已使用">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'CouponDistributionRecord' }"
            class="main-color pointer text-none"
          >
            {{ scope.row.out_num }}/{{ scope.row.used_num }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="state_txt"
        align="center"
        label="优惠券类型"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="threshold"
        align="center"
        label="条件"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>订单商品金额大于{{ scope.row.threshold }}元</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="valid_period"
        align="center"
        label="有效期"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="status"
        align="center"
        label="状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            @click="onHandleStatus(scope.row)"
            :type="
              stateList.find((item) => {
                return item.value === (scope.row.status || -1);
              }).label == '启用中'
                ? 'success'
                : 'warning'
            "
          >
            {{
              stateList.find((item) => item.value == scope.row.status)?.label ||
              ""
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="purchase_deduction_amount"
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="onHandleReissue(scope.row)">
            补发
          </el-button>
          <el-button type="text" @click="sending(scope.row)"> 群发 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="群发优惠券"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="上传文件" prop="memberFile">
          <el-upload
            class="upload-demo"
            :action="`${BASE.PRO1}/uploadFile`"
            :headers="{ token: token }"
            ref="upload"
            accept=".xlsx, .xls"
            :on-success="successFile"
            :on-error="errorFile"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传.xlsx, .xls文件</div>
          </el-upload>
          <el-button size="small" type="primary" @click="openfile"
            >下载模版</el-button
          >
        </el-form-item>
        <el-form-item label="生效城市仓" prop="logistics_id">
          <el-select
            v-model="value2"
            multiple
            filterable
            @change="changelogistics"
            placeholder="请选择生效城市仓"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="selectAll(item)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定日期" prop="time_start">
          <el-date-picker
            v-model="value1"
            style="width: 100%"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDate"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BASE } from "@/api";
import {
  postCouponStatus,
  send_coupon_batch,
} from "@/api/generalControl/coupon";
import { STATE_ENUM } from "../../utils/enum/index";
import { getAllLogisticsList } from "@/api/member/index";
export default {
  name: "TableList",
  props: ["tableData"],
  data() {
    return {
      BASE,
      STATE_ENUM,
      stateList: Object.values(STATE_ENUM),
      centerDialogVisible: false,
      value1: [], //选择时间
      value2: [], //选择生效城市仓
      options: [], //选择生效城市仓列表,
      fileList: [],
      ruleForm: {
        memberFile: "", //文件流
        coupon_id: "", //优惠卷id
        logistics_id: "", //城市仓id
        time_start: "", //开始时间
        time_end: "", //结束时间
      },
      loading: false,
      rules: {
        time_start: [
          { required: true, message: "请选择指定日期", trigger: "change" },
        ],
        logistics_id: [
          { required: true, message: "请选择生效城市仓", trigger: "change" },
        ],
        memberFile: [
          { required: true, message: "请上传文件", trigger: "change" },
        ],
      },
      token: sessionStorage.getItem("token"),
    };
  },
  mounted() {
    // this.token = localStorage.getItem("token");

    this.hqlist();
  },
  watch: {
    value2: {
      handler(newVal, oldVal) {
        this.ruleForm.logistics_id = this.value2.join(",");
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    openfile() {
      this.download(
        "https://jiunong-purchase.oss-cn-beijing.aliyuncs.com/general/coupon/couponSendBatchTemplate.xlsx",
        "群发优惠券模版"
      );
    },
    download(url1, fileName) {
      let self = this;
      const url = url1;
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function () {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
    selectAll(item) {
      if (item.type === "All") {
        this.value2 = [0];
      } else {
        this.value2 = this.value2.filter((item) => item !== 0);
      }
      console.log(this.value2, item, "111111111111111111111111112222");
    },
    hqlist() {
      getAllLogisticsList({
        status: 1,
        logistics_type: 1,
      }).then((res) => {
        this.options = res.data;
        this.options.unshift({
          name: "全选",
          id: 0,
          type: "All",
        });
      });
    },
    /**
     * 上传操作
     */
    successFile(e) {
      this.ruleForm.memberFile = e.data;
    },
    errorFile(e) {
      this.ruleForm.memberFile = "";
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    /**
     * 上传接口
     */
    async ajaxPostUpload(file, result) {
      const params = {
        file: result,
      };
      this.$api.general.uploadFile(params).then((res) => {
        this.ruleForm.memberFile = res.data;
      });
    },
    beforeRemove(file, fileList) {
      this.ruleForm.memberFile = "";
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          send_coupon_batch(this.ruleForm)
            .then((res) => {
              this.loading = false;
              this.centerDialogVisible = false;
              this.$message({
                type: "success",
                message: "优惠劵群发成功",
              });
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //生效城市仓选择
    changelogistics() {
      // this.ruleForm.logistics_id = this.value2.join(",");
      // console.log(this.ruleForm.logistics_id,'11111111111111111111111111111111')
    },
    //指定日期选择
    changeDate() {
      console.log(this.value1);
      if (this.value1) {
        this.ruleForm.time_start = this.value1[0];
        this.ruleForm.time_end = this.value1[1];
      } else {
        this.ruleForm.time_start = "";
        this.ruleForm.time_end = "";
      }
    },
    //群发
    sending(row) {
      this.centerDialogVisible = true;
      this.$nextTick(() => {
        this.value1 = "";
        this.value2 = [];
        this.resetForm();
        this.$refs.upload.clearFiles();
        this.ruleForm.coupon_id = row.id;
      });
    },
    /**
     * 补发
     */
    onHandleReissue(row) {
      this.$emit("onHandleCoupon", "addData", row);
    },
    /**
     * 启用 禁用
     */
    onHandleStatus(row) {
      this.$confirm(
        row.status == STATE_ENUM["disable"].value
          ? `确定启用么？`
          : `确定禁用么？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            const res = await postCouponStatus({
              id: row.id,
              status:
                row.status == STATE_ENUM["disable"].value
                  ? STATE_ENUM["used"].value
                  : STATE_ENUM["disable"].value,
            });
            this.$message({
              type: "success",
              message:
                row.status == STATE_ENUM["disable"].value
                  ? "启用成功"
                  : "禁用成功",
            });
            this.$emit("submit-form");
          } catch (error) {
            console.log(error, "postCouponStatus");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message:
              row.status == STATE_ENUM["disable"].value
                ? "取消启用"
                : "取消禁用",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
