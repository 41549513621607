<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <!-- <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div> -->

      <!-- 合计 -->
      <div v-if="topTip" class="top-tip">{{ topTip }}</div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @submit-form="submitForm"
        @onHandleCoupon="onHandleCoupon"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 补发用户弹窗 start -->
    <coupon-config-dialog
      ref="couponConfig"
      @submit-form="submitForm"
    ></coupon-config-dialog>
    <!-- 补发用户弹窗 end -->
  </section>
</template>
<script>
import couponConfigDialog from "./modules/addConfig/index.vue";
import {
  getCouponList,
  getCouponRecordCount,
} from "@/api/generalControl/coupon";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "coupon",
  components: { couponConfigDialog, TableList },
  data() {
    return {
      total: 0,
      loadingData: false,
      formData: {
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
      topTip: "", // 顶部合计文案
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.getAjaxCouponList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.getAjaxCouponList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.getAjaxCouponList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.getAjaxCouponList();
      this.ajaxGetCouponRecordCount();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.getAjaxCouponList();
    },
    /**
     * 补发用户
     */
    onHandleCoupon(func, val) {
      this.$refs.couponConfig[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.getAjaxCouponList();
    },
    /**
     * 获得列表数据
     */
    async getAjaxCouponList() {
      this.loadingData = true;
      try {
        const { data } = await getCouponList(this.formData);
        this.tableData = data.list;
        this.total = data.total;
      } catch (err) {
        console.error("ajax getCouponList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 获得发券合计
     */
    async ajaxGetCouponRecordCount() {
      try {
        const { data } = await getCouponRecordCount();
        // 剩余金额
        const last = (
          Number(data.deduction_amt) - Number(data.coupon_amt)
        ).toFixed(2);
        this.topTip = `优惠券累计已扣款 ${data.deduction_amt}元，已发券 ${data.coupon_amt}元，剩余${last}元`;
      } catch (err) {
        console.error("ajax getCouponRecordCount err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
  }
}
.top-tip {
  line-height: 30px;
  color: $danger-color;
}
</style>
