/** 状态枚举 */
export const STATE_ENUM = {
  used: {
    value: 1,
    label: "启用中",
  },
  disable: {
    value: -1,
    label: "禁用",
  },
};
