<template>
  <div>
    <!--  添加关联用户弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="添加用户" prop="member_id">
            <div
              v-for="(item, index) in userIdsList"
              :key="index"
              class="d-flex a-center mb-10"
            >
              <el-select
                v-model="item.value"
                filterable
                remote
                placeholder="请输入用户手机号"
                :remote-method="remoteMethod"
                @visible-change="(val) => onChangeVisible(val, index)"
                :loading="loading"
                @change="change"
                clearable
                :ref="`selectRef${index}`"
                :popper-class="'custom-select-dropdown'"
                :popper-append-to-body="false"
              >
                <div>
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="`${item.fullname}【${item.mobile}】`"
                    :value="item.id"
                  >
                  </el-option>
                </div>
                <div style="width: 100%; text-align: center" v-if="showNext">
                  {{ textwarn }}
                </div>
              </el-select>
              <div style="margin: 0px 10px">
                <i
                  class="el-icon-plus"
                  style="color: #13ae67"
                  @click="addOption"
                ></i>
              </div>
              <div style="margin: 0px 10px; width: 30px">
                <i
                  class="el-icon-delete"
                  style="color: #13ae67"
                  @click="deleteOption(item)"
                  v-if="index != 0"
                ></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import {
  getCouponMemberList,
  postSendCoupon,
} from "@/api/generalControl/coupon";

export default {
  name: "couponConfigDialog",
  data() {
    return {
      refIndex: "",
      options: [],
      showNext: false,
      textwarn: "努力加载中...",
      id: "",
      dataLock: false,
      name: "",
      page: 1,
      pageSize: 20,
      loading: false,
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制数据，用于初始化
      platForm: {
        member_id: "",
      },
      userIdsList: [{ value: "" }],
      rules: {
        member_id: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: function (rule, value, callback) {
              if (value == "") {
                callback(new Error("请输入用户手机号"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     * 下拉框滚动加载下一页数据
     */
    loadMoreOptions() {
      console.log(this.showNext, "111");
      if (this.showNext) {
        console.log("还没请求完呢");
        return;
      }
      this.showNext = true;
      this.page++;
      this.getOptionsList();
    },
    /**
     * 监听下拉框滚动事件
     */
    addScrollEvent() {
      let tmpRef = `selectRef${this.refIndex}`;
      const dropdownMenu = this.$refs[tmpRef][0].$refs.popper.$el.querySelector(
        ".el-select-dropdown__wrap"
      );
      console.log(dropdownMenu, "添加滚动事件");
      dropdownMenu.addEventListener("scroll", this.handleScroll);
    },
    /**
     * 移除下拉监听的滚动事件
     */
    removeScrollEvent() {
      let tmpRef = `selectRef${this.refIndex}`;
      const dropdownMenu = this.$refs[tmpRef][0].$el.querySelector(
        ".el-select-dropdown__wrap"
      );
      console.log(dropdownMenu, "移除滚动事件");
      dropdownMenu.removeEventListener("scroll", this.handleScroll);
    },
    /**
     * 下拉框滚动到底部 加载下一页数据
     */
    handleScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        console.log(scrollTop, scrollHeight, clientHeight, "到底部啦====");
        this.loadMoreOptions();
      }
    },
    /**
     * 下拉框出现/隐藏时触发
     */
    onChangeVisible(e, index) {
      this.refIndex = index; // 获取下拉框下标
      if (e) {
        this.$nextTick(() => {
          this.addScrollEvent();
        });
      } else {
        this.removeScrollEvent();
        this.options = [];
        this.name = "";
        this.dataLock = false;
        this.page = 1;
      }
      // 每次 下拉框出现/隐藏  滚动到顶部
      let tmpRef = `selectRef${index}`;
      const dropdownMenu = this.$refs[tmpRef][0].$el.querySelector(
        ".el-select-dropdown__wrap"
      );
      dropdownMenu.scrollTop = 0;
    },
    /**
     * 下拉框改变
     */
    change(e) {
      let tmpUserIds = this.userIdsList.map((obj) => obj.value);
      this.platForm.member_id = tmpUserIds.join(",");
      this.$refs.platForm.validateField("member_id");
    },
    /**
     * 添加input 选择用户
     */
    addOption() {
      this.userIdsList.push({
        value: "",
      });
    },
    /**
     * 删除input 选择用户
     */
    deleteOption(item) {
      var index = this.userIdsList.indexOf(item);
      if (index !== -1) {
        this.userIdsList.splice(index, 1);
      }
    },
    /**
     *下拉框远程搜索
     */
    remoteMethod(query) {
      if (query !== "") {
        console.log(query, "重新请求000");
        console.log(this.refIndex, "===");
        this.tool.debounce(async () => {
          // 重新请求数据时 该下拉框滚动到顶部
          let tmpRef = `selectRef${this.refIndex}`;
          const dropdownMenu = this.$refs[tmpRef][0].$el.querySelector(
            ".el-select-dropdown__wrap"
          );
          dropdownMenu.scrollTop = 0;
          // 重置数据
          this.loading = true;
          this.name = query;
          this.page = 1;
          this.options = [];
          this.dataLock = false;
          this.getOptionsList(query);
        });
      } else {
        this.options = [];
        this.name = "";
        this.page = 1;
        this.dataLock = false;
      }
    },
    /**
     *获取用户列表
     */
    getOptionsList: async function (e) {
      if (!this.dataLock) {
        try {
          const res = await getCouponMemberList({
            page: this.page,
            name: e ? e : this.name,
            pageSize: this.pageSize,
          });
          if (res.data.list.length >= 20) {
            this.options = this.options.concat(res.data.list);
            this.textwarn = "努力加载中...";
            this.dataLock = false;
          } else if (res.data.list.length < 20) {
            this.options = this.options.concat(res.data.list);
            this.textwarn = "到底了";
            this.dataLock = true;
          } else if (res.data.list.length == 0) {
            this.options = [];
            this.textwarn = "到底了";
            this.dataLock = true;
          }
          console.log(this.options, "eee");
        } catch (error) {
          console.log(error, "postVersatileRegionProductSearch");
        } finally {
          this.loading = false;
          this.showNext = false;
        }
        console.log(this.showNext, this.dataLock, this.textwarn, "text====");
      }
    },
    /**
     *初始化数据
     */
    initData() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
      this.userIdsList = [{ value: "" }];
    },
    /**
     *新增
     */
    addData(val) {
      this.id = val.id; // 补发优惠券的id
      this.platFormTitle = "添加用户";
      this.platForm = {
        ...this.platFormClone,
      };
      this.initData();
    },
    /**
     * 提交表单
     */
    submitFrom: debounce(function () {
      // 判断下拉选择用户是否都填写
      let tmpArr = [];
      this.userIdsList.map((item) => {
        if (item.value == "") {
          tmpArr.push(item);
        }
      });
      if (tmpArr.length > 0) {
        return this.tool.message("请选择用户", "error");
      }
      this.$refs.platForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        let tmpUserIds = this.userIdsList.map((obj) => obj.value); // 获取所有用户id
        try {
          await postSendCoupon({
            coupon_id: this.id,
            member_id: tmpUserIds.join(","),
          });
          this.tool.message("添加成功", "success");
          this.$emit("submit-form"); // 刷新列表
        } catch (error) {
          console.error("postSendCoupon", error);
        } finally {
          this.dialogFormVisible = false;
          this.submitLoading = false;
        }
      });
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
  .tipWrap {
    color: red;
    margin-left: 10px;
  }
}

/deep/ .custom-select-dropdown {
  .el-select-dropdown__wrap {
    max-height: 300px !important;
    overflow-y: auto !important;
    box-sizing: border-box;
  }
}
</style>
